@import '~ht-styleguide/build/grid_classes';
@import 'src/styles/_variables';

.basicButton {
  @include with-lg-grid {
    width: 140px !important;
  }
}

.menuIcon {
  font-size: 20px;
  color: $ht-navy-700;

  @include with-md-grid {
      font-size: 24px;
  }
}

