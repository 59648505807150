.ht-menu-item {
  display: flex;
  width: 100%;
  padding: 20px 24px;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  background-color: var(--n000);

  transition: background-color 150ms ease;

  color: var(--n900);
}

.ht-menu-item:hover {
  background-color: var(--n100);
  color: var(--n900);
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .ht-menu-item {
    padding: 16px;
  }
}

.ht-menu-item-img {
  width: 24px;
  height: 24px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .ht-menu-item-img {
    width: 20px;
    height: 20px;
  }
}

.ht-menu-item-text {
  color: var(--n900);
  font-size: 20px;
  line-height: 24px;
  font-variation-settings: "wght" 550;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.ht-menu-item-text:hover {
  color: var(--n900);
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .ht-menu-item-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .ht-menu-item-text {
    position: relative;
    top: 1px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.4px;
  }
}