@import '~ht-styleguide/build/spacing';

.staticText {
  opacity: 0.7;
}

.animatedParentWrapper {
  display: block;

  @media screen and (min-width: 450px) {
    display: block;
  }
  @media screen and (min-width: 991px) {
    display: block;
    height: 40px;
  }
  @media screen and (min-width: 1220px) {
    display: inline;
    height: unset;
  }
};

.dynamicText {
  color: $ht-white;
}

.carousel {
  white-space: nowrap;
}

