.ht-homepage-hero {
  position: relative;
  z-index: 3;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: var(--blue700);
}

@media screen and (max-width: 767px) {
  .ht-homepage-hero {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 479px) {
  .ht-homepage-hero {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.ht-container {
  position: relative;
  z-index: 1;

  width: 1280px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 32px;
  padding-left: 32px;
}

@media screen and (min-width: 1440px) {
  .ht-container {
    margin-bottom: 0px;
    padding-top: 0px;
  }
}

@media screen and (max-width: 991px) {
  .ht-container {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 767px) {
  .ht-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.ht-container.lg {
  width: 1440px;
  padding-right: 64px;
  padding-left: 64px;
}

@media screen and (max-width: 991px) {
  .ht-container.lg {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 767px) {
  .ht-container.lg {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 479px) {
  .ht-container.lg {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.ht-container.sm {
  width: 720px;
}

.ht-contained-hero {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 8%;
}

@media screen and (max-width: 767px) {
  .ht-contained-hero {
    flex-direction: column;
    grid-row-gap: 32px;
  }
}

@media screen and (max-width: 767px) {
  .ht-contained-hero.switch-on-mobile {
    flex-direction: column-reverse;
  }
}

.ht-contained-hero-content {
  position: relative;
  z-index: 1;
  max-width: 100%;
  flex: 1;
}

@media screen and (min-width: 1440px) {
  .ht-contained-hero-content {
    z-index: 1;
  }
}

@media screen and (max-width: 991px) {
  .ht-contained-hero-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .ht-contained-hero-content.inset {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 479px) {
  .ht-contained-hero-content.inset {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.txt-n000 {
  color: var(--n000-1b2ae02d);
}

.align-center {
  text-align: center;
}

@media screen and (min-width: 1440px) {
  .align-center {
    padding-bottom: 0px;
  }
}

.opacity-70 {
  opacity: 0.7;
}

.txt-underline {
  text-decoration: underline;
}

.ht-homepage-hero-title {
  display: inline;

  opacity: 0.7;

  color: var(--n000);
  font-size: 44px;
  line-height: 48px;
  font-variation-settings: "wght" 550;
  letter-spacing: -1.6px;
}

@media screen and (max-width: 991px) {
  .ht-homepage-hero-title {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -1.8px;
  }
}

@media screen and (max-width: 767px) {
  .ht-homepage-hero-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1.15px;
  }
}

.ht-homepage-device-name-carousel {
  display: inline;
  padding-left: 8px;
  color: var(--n000);
}

.small {
  height: 16px;
}

@media screen and (max-width: 991px) {
  .div-block-73 {
    padding-top: 32px;
  }
}

.txt-body-lg {
  font-size: 20px;
  line-height: 28px;
  font-variation-settings: "wght" 400;
  letter-spacing: -0.25px;
}

@media screen and (max-width: 991px) {
  .txt-body-lg {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
}

@media screen and (max-width: 767px) {
  .txt-body-lg {
    font-size: 16px;
    letter-spacing: -0.15px;
  }
}

.small-2 {
  height: 32px;
}

@media screen and (max-width: 991px) {
  .small-2 {
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .small-2 {
    height: 16px;
  }
}

.ht-homepage-hero-img-container {
  position: relative;
  bottom: -32px;

  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .ht-homepage-hero-img-container {
    display: none;
    width: 100%;
    height: 280px;
    min-height: auto;
    min-width: 100%;
    flex: 0 auto;
  }
}

@media screen and (max-width: 479px) {
  .ht-homepage-hero-img-container {
    height: 220px;
  }
}

.ht-homepage-hero-img {
  position: relative;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.ht-inner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ht-inner-img.ht-fit {
  object-fit: contain;
}

@media screen and (max-width: 767px) {
  .ht-inner-img.ht-fit.ht-fit-left {
    object-position: 0% 50%;
  }
}

.ht-inner-img.cover-position-bottom {
  object-position: 50% 100%;
}

.ht-bg-fill-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;

  overflow: hidden;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.ht-homepage-hero-blob {
  position: relative;
  top: 200px;
  z-index: 0;
  width: 100%;
  max-width: none;
}

@media screen and (max-width: 767px) {
  .ht-homepage-hero-blob {
    top: 104px;
  }
}

@media screen and (max-width: 479px) {
  .ht-homepage-hero-blob {
    top: 156px;
  }
}