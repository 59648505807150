.ht-category-select-dropdown-container {
  width: 100%;
}

.ht-category-select-dropdown {
  display: flex;
  width: 100%;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  border-radius: 8px;

  background-color: var(--n000);

  box-shadow: 0 1px 16px 0 hsla(208.125, 29.63%, 21.18%, 0.06);
}

@media screen and (max-width: 991px) {
  .ht-category-select-dropdown {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ht-category-select-dropdown {
    padding: 16px 20px;
  }
}

.ht-category-select-dropdown-label {
  flex: 1;

  color: var(--blue700);
  font-size: 24px;
  line-height: 24px;
  font-variation-settings: "wght" 550;
  letter-spacing: -0.75px;
}

@media screen and (max-width: 991px) {
  .ht-category-select-dropdown-label {
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ht-category-select-dropdown-label {
    font-size: 18px;
    line-height: 18px;
  }
}

.ht-category-select-dropdown-arrow {
  width: 24px;
}

@media screen and (max-width: 767px) {
  .ht-category-select-dropdown-arrow {
    width: 20px;
  }
}

.ht-category-select-dropdown-menu {
  overflow: hidden;
  width: 100%;
  margin-top: 8px;
  padding-top: 12px;
  padding-bottom: 12px;

  border-radius: 8px;

  background-color: var(--n000);

  box-shadow: 0 1px 16px 0 hsla(208.125, 29.63%, 21.18%, 0.06);
}

@media screen and (max-width: 767px) {
  .ht-category-select-dropdown-menu {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}