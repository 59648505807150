@import 'src/styles/mixins';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';
@import '~ht-styleguide/build/grid_classes';

.banner {
  background: $ht-blue-700;
  color: $ht-white;
  position: relative;
  overflow: hidden;
  @include spacing(padding, 3.5 2);

  @include with-md-grid {
    @include spacing(padding, 2);
  }
}

.blobs {
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
  }

  &::before {
    background-image: url('/_nextassets_/blobs/blob_left.svg');
    width: 135px;
    height: 60px;
    top: -20px;
    left: -30px;

    @include with-md-grid {
      left: -10px;
      top: -5px;
    }
  }

  &::after {
    background-image: url('/_nextassets_/blobs/blob_right.svg');
    width: 120px;
    height: 26px;
    bottom: 0;
    right: 0;

    @include with-md-grid {
      width: 183px;
      height: 39px;
    }
  }
}

.text {
  text-align: center;

  @include with-md-grid {
    text-align: left;
  }
}

.button {
  @include spacing(margin-top, 3);
  @include with-md-grid {
    @include spacing(margin-top, 0);
    @include spacing(margin-left, 4);

    width: auto;
  }
}
