@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';

.banner {
  display: flex;
  justify-content: center;

  &.banner {
    @include spacing(padding, 2);
  }

  &.light {
    background-color: rgba($ht-blue-700, 0.1);
  }
}

.carousel {
  line-height: 0;
}

.slide {
  @include spacing(padding-bottom, 0);
}

.item {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;

  & + & {
    @include spacing(margin-top, 1.25);

    @include with-lg-grid {
      @include spacing(margin-top, 0);
    }
  }

  &.light {
    color: $ht-blue-700 !important;
  }
}

.toolkit {
  @include spacing(margin-right, 1.125);

  line-height: 0;
  font-size: 21px;

  @include with-lg-grid {
    font-size: 25px;
  }
}

.service {
  @include spacing(margin-right, 1);

  line-height: 0;
  font-size: 30px;

  @include with-lg-grid {
    font-size: 35px;
  }
}

.check {
  line-height: 0;
  font-size: 20px;
  @include spacing(margin-top, -0.25);
  @include spacing(margin-right, 1.375);

  @include with-lg-grid {
    font-size: 21px;
  }
}
